import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispositivoIsencaoService {

  private url = environment.url+'/dispositivo';
  constructor(
    private http: HttpClient
  ) { 
    
  }

  public salvarDispositivoIsencao(formData: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-dispositivo-isencao`, formData);
  }
  public adicionarImagem(formData: any): Observable<any> {
    return this.http.post<any>(`${this.url}/eng-dispositivo-isencao/incluir-imagens`, formData);
  }


  public editarDispositivoIsencao(formData: any): Observable<any> {
    return this.http.put<any>(`${this.url}/eng-dispositivo-isencao`, formData);
  }

  public consultarTodasDispositivoIsencao(pagina:number,quantidadePorPagina:number): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-dispositivo-isencao/${pagina}/${quantidadePorPagina}`);
  }
  public consultarTodasDispositivoIsencaoSemPaginacao(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/eng-dispositivo-isencao`);
  }


  public consultaImagemPorNome(nomeArquivo: string): Observable<Blob> {
    return this.http.get(`${this.url}/eng-dispositivo-isencao/download/${nomeArquivo}`, {responseType: 'blob'});
  }
  public consultarPorId(idDispositivoIsencao: number): Observable<any> {
    return this.http.get<any>(
      `${this.url}/eng-dispositivo-isencao/${idDispositivoIsencao}`
    );
  }
  
  public consultarDispositivoPorNome(nomeDispositivo: string): Observable<any> {
    return this.http.get<any>(`${this.url}/eng-dispositivo-isencao/nome/${nomeDispositivo}`);
  }

  public deletarDispositivoIsencao(id:number): Observable<any> {
    return this.http.delete(`${this.url}/eng-dispositivo-isencao/excluir-imagem/${id}`);
  }

  public deletarDispositivo(id:number): Observable<any> {
    return this.http.delete(`${this.url}/eng-dispositivo-isencao/${id}`);
  }
}
