import { Imagens } from "../imagens";

export class DispositivoIsencao {
  id: number = 0;
  nome: string = '';
  processoAplicavel: string = '';
  qtdeDisponivel: number = 0;
  observacao:string = ''
  imagens:Imagens[] = [];
}
